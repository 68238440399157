import { trigger, transition, style, query, animate, state, stagger, keyframes } from "@angular/animations";

export const routerAnimation=
trigger('routerAnimation',[
  transition('* <=> *',[
    query(':enter',[
      style({
        position:'absolute',
        left:0,
        width:'100%',
        opacity:0,
        transform:'translateY(50px)',
        height: 'calc(100% - 100px)'
      })
    ],{optional:true}),
    query(':enter',[
      animate('600ms ease',
      style({
        opacity:1,transform:'translateY(0)',
        height: 'calc(100% - 100px)'
      }))
    ],{optional:true})
  ])
]);

  export const fade = 
  trigger( 'fade', [
    transition(':enter, :leave',[
      animate('400ms')
    ]),
    state('void',style({opacity:0}))
  ]);

  export const listAnimation = trigger('listAnimation',[
    transition('* => *',[
      query(':enter',style({opacity:0}),{optional:true}),
      query(':enter',stagger('300ms',[
        animate('1s ease-in',keyframes([
          style({opacity:0,transform:'translateY(80px)',offset:0}),
          style({opacity:0.5,transform:'translateY(25px)',offset:0.3}),
          style({opacity:1,transform:'translateY(0)',offset:1})
        ]))
      ]),{optional:true})
    ])
  ]);

  export const buttonAnimation = trigger('buttonAnimation',[
    transition('* => open',[
     animate('600ms 10ms ease',keyframes([
       style({transform: 'rotate(360deg)'}),
     ]))
    ]),
    state(':enter',
    style({position:'relative',backgroundColor:'red'}))
  ]);
  export const dialogAnimation = trigger('dialogAnimation',[
    transition('',[
      
    ])
  ])