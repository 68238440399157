export class AppConstants {
    //authentication
    public static loginAPI = 'login';
    public static logoutAPI = 'logout';
    public static refreshTokenAPI = 'refreshToken'
    public static forgotPasswordAPI = 'forgotPassword';
    public static resetPasswordAPI = 'resetPassword';
    //profile
    public static getProfileAPI = 'users/profile';
    public static updateProfileAPI = 'users';
    //popular
    public static popularAffirmationsAPI = 'popular';
    //quotes
    public static quotesAPI = 'quotes';
    //admins
    public static adminsAPI = 'adminUsers';
    public static usersAPI = 'appUsers';
    public static createAdminAPI = 'adminUsers/createAdmin';
    //affirmations
    public static categoriesListAPI = 'affirmations/categories';
    public static affirmationsListAPI = 'affirmations';
    public static createAffirmationAPI = 'affirmations/create';
    public static updateAffirmationAPI = 'affirmations/';
    public static deleteAffirmationAPI = 'affirmations/delete/';
    //meditations
    public static meditationsCategoriesListAPI = 'meditations/categories';
    public static meditationsListAPI = 'meditations';
    public static createMeditationAPI = 'meditations/add';
    public static updateMeditationAPI = 'meditations/edit/';
    public static deleteMeditationAPI = 'meditations/delete/';


}   