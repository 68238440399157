import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { fade } from './animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations:[fade]
})
export class AppComponent {
  title = 'mind-master';
  prepareRoute(outlet:RouterOutlet){
return outlet && outlet.activatedRouteData;
  }
}