import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { APIResponse } from './auth.model';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );
    constructor(
        private route: Router,
        private authService: AuthService
    ) { }
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('access_token');
        if (token) {
            request = this.addToken(request, token);
        }

        return next.handle(request).pipe(
            catchError((error) => {
                console.log('error is----', error);
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    return this.handle401Error(request, next);
                } else {
                    return throwError(error);
                }
            })
        ) as Observable<HttpEvent<any>>;;
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'access_token': token,
            },
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            return this.authService
                .refreshToken({ 'refresh_token': localStorage.getItem('refresh_token') })
                .pipe(
                    switchMap((res: any) => {
                        console.log('refresh token response--', res);
                        const response = res as APIResponse<any>;

                        if (response.status_code === 200) {
                            localStorage.setItem('access_token', res.data.access_token);
                            this.isRefreshing = false;
                            this.refreshTokenSubject.next(res.data.access_token);
                            return next.handle(this.addToken(request, res.data.access_token));
                        } else {
                            localStorage.clear();
                            this.route.navigate(['/login']);
                            return [];
                        }
                    }),
                    catchError((error: any) => {
                        console.log('error in refresh token', error);
                        localStorage.clear();
                        this.route.navigate(['/login']);
                        return [];
                    })
                );
        } else {
            return this.refreshTokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((jwt) => {
                    return next.handle(this.addToken(request, jwt));
                })
            );
        }
    }
}
