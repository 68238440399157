import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { APIResponse, LoginResponse } from './auth.model';
import { AppConstants } from '../app-constants';
import { Status } from '../helpers/app-enums';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService {

  constructor(private httpClient: HttpClient) {
  }

  isUserLoggedIn() {
    const accesstoken = localStorage.getItem('access_token') as string;
    if (accesstoken === null) {
      return false;
    } else {
      return true;
    }
  }

  callLoginService(data: any, callback: (status: number, response: APIResponse<LoginResponse> | string) => void) {
    const loginUrl = environment.basicAPI + AppConstants.loginAPI;
    this.httpClient.post(loginUrl, data).subscribe((data: any) => {
      const response = data as APIResponse<LoginResponse>;
      if (response.status_code === 200) {
        console.log('success login response', response.data)
        callback(Status.SUCCESS, response);
        localStorage.setItem('access_token', response.data.access_token as string);
        localStorage.setItem('refresh_token', response.data.refresh_token as string);
        localStorage.setItem('email', response.data.email as string);
      } else {
        callback(Status.ERROR, response.message);
      }
    },
      (error: HttpErrorResponse) => {
        console.log('error while logging in', error)
        callback(Status.ERROR, error.message);
      });
  }

  callForgotPasswordService(data: any, callback: (status: number, response: APIResponse<[]> | string) => void) {
    const forgotPasswordUrl = environment.basicAPI + AppConstants.forgotPasswordAPI;
    this.httpClient.post(forgotPasswordUrl, data).subscribe((data: any) => {
      const response = data as APIResponse<[]>;
      if (response.status_code === 200) {
        callback(Status.SUCCESS, response);
      } else if (response.status_code === 403) {
        callback(Status.ERROR, response.message);
      }
    },
      (error: HttpErrorResponse) => {
        callback(Status.ERROR, error.error.message);
      });
  }

  callResetPasswordService(data: any, callback: (status: number, response: APIResponse<[]> | string) => void) {
    const resetPasswordUrl = environment.basicAPI + AppConstants.resetPasswordAPI;
    this.httpClient.post(resetPasswordUrl, data).subscribe((data: any) => {
      const response = data as APIResponse<[]>;
      if (response.status_code === 200) {
        callback(Status.SUCCESS, response);
      } else {
        callback(Status.ERROR, response.message);
      };
    },
      (error: HttpErrorResponse) => {
        callback(Status.ERROR, error.error.message);
      });
  }

  refreshToken(refreshToken: any): Observable<any> {
    return this.httpClient.put<any>(`${environment.basicAPI}refreshToken`,
      null,
      { headers: refreshToken }
    );
  }
}